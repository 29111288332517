import {
    Container
} from '../vendor/mui'
import {useEffect, useState} from "react";
import {Booking} from "../business/Booking";
import RoomFilter from "./RoomFilter";
import MonthlyPromo from "./MonthlyPromo";

export default function MonthlyBooker(props) {
    const display = () => {
        if (props.hide === true){
            return { display: 'none' }
        }
    }

    const find = async (date, timeUnit, setRooms) => {
        await Booking.filterRoomsMonthly(date, timeUnit, props.houseId, (response) => {
            setRooms(response)
        });
    }

    useEffect(() => {
    },[])

    return (
        <Container>
            <RoomFilter
                display={display()}
                title={"Bulanan"}
                subtitle={"Berapa bulan?"}
                type={"monthly"}
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15]}
                find={find}>
            </RoomFilter>
            <MonthlyPromo></MonthlyPromo>
        </Container>
    )
}
