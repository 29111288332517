export default function MonthlyPromo() {
    return (
        <div>
            <h4>Promo!</h4>
            <p>
                Pilih 7 bulan untuk gratis 1 bulan
            </p>
            <p>
                Pilih 15 bulan untuk gratis 3 bulan
            </p>
        </div>
    )
}