import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
export class UpdateProfile {
    static async run(profileUrl = Api.updateProfileUrl(),data, onSuccess, onFailed) {
        const body = new FormData()
        body.append('user_profile[name]', data.name())
        body.append('user_profile[relative_name]', data.relativeName())
        body.append('user_profile[phone]', data.phone())
        body.append('user_profile[relative_phone]', data.relativePhone())
        if(data.photo()){
            body.append('user_profile[photo]', data.photo(), data.photo().name)
        } else {
            body.append('user_profile[photo]', data.photo())
        }
        if(data.identityPhoto()){
            body.append('user_profile[identity_photo]', data.identityPhoto(), data.identityPhoto().name)
        } else {
            body.append('user_profile[identity_photo]', data.identityPhoto())
        }
        body.append('user_profile[work]', data.work())
        body.append('user_profile[work_address]', data.workAddress())
        body.append('user_profile[house_address]', data.houseAddress())
        body.append('user_profile[religion]', data.religion())
        body.append('user_profile[marriage_status]', data.marriageStatusBool())
        body.append('user_profile[relative_description]', data.relativeDescription())
        body.append('user_profile[birth_place]', data.birthPlace())
        body.append('user_profile[birth_date_unix]', data.birthDateUnix())

        try {
            const response = await FetchAdapter.put(profileUrl, body)
            onSuccess(response);
        } catch(e) {
            onFailed(e);
        }
    }
}