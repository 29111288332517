import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
export class ConvertCoinVouchers {
    static async run(voucherTypeId, onSuccess, onFailed) {
        const url = Api.convertVoucherType()
        const body = new FormData()
        body.append('voucher_type_id', voucherTypeId)

        try {
            const response = await FetchAdapter.post(url, body)
            let voucherCode = response['voucher']['code'];

            onSuccess(voucherCode);
        } catch {
            onFailed();
        }
    }
}