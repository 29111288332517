import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
export class CheckProfile {
    static async run(url=Api.coinUrl(), onSuccess, onFailed) {
        const response = await FetchAdapter.get(url)
        if(response != undefined) {
            onSuccess(response)
        } else {
            onSuccess({ coin_count: '...', history: [] })
        }
    }
}