import Routes from '../config/Routes'
import {Box, Container, Button, TextField, FormControl, Select, MenuItem, InputLabel, Grid, Item} from '../vendor/mui'
import TextFieldComponent from '../components/TextField'
import { ProfileModel } from  '../business/ProfileModel'
import { UpdateProfile } from  '../business/UpdateProfile'
import { GetProfile } from  '../business/GetProfile'
import { useEffect, useState } from "react";
import { Session } from '../business/Session';
import Loading from "../components/Loading";
import ImageCompressor from "../vendor/ImageCompressor";

export default function ProfileTemplate(props) {
    const [name, setName] = useState("")
    const [pob, setPob] = useState("")
    const [dob, setDob] = useState("")
    const [religion, setReligion] = useState("")
    const [marital, setMarital] = useState("")
    const [waNumber, setWaNumber] = useState("")
    const [work, setWork] = useState("")
    const [address, setAddress] = useState("")
    const [officeAddress, setOfficeAddress] = useState("")
    const [relativeName, setRelativeName] = useState("")
    const [relativePhone, setRelativePhone] = useState("")
    const [relativeRelationship, setRelativeRelationship] = useState("")

    const [profileImage, setProfileImage] = useState("")
    const [identityImage, setIdentityImage] = useState("")

    const [profileImageUrl, setProfileImageUrl] = useState("")
    const [identityImageUrl, setIdentityImageUrl] = useState("")

    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])

    useEffect(() => {
        Session.whenLoggedOut(() => {
            Routes.redirectTo(Routes.loginPath())
        })
    }, [errors])
    useEffect(() => {
        setLoading(true)
        currentProfile();
    }, [])

    const currentProfile = async () => {
        let profileModel = await GetProfile.run(props.profileUrl)
        setLoading(false)

        setName(profileModel.name())
        setDob(profileModel.birthDate())
        setPob(profileModel.birthPlace())
        setReligion(profileModel.religion())
        setMarital(profileModel.marriageStatus())
        setWaNumber(profileModel.phone())
        setWork(profileModel.work())
        setAddress(profileModel.houseAddress())
        setOfficeAddress(profileModel.workAddress())
        setRelativeName(profileModel.relativeName())
        setRelativeRelationship(profileModel.relativeDescription())
        setRelativePhone(profileModel.relativePhone())

        setProfileImageUrl(profileModel.photo())
        setIdentityImageUrl(profileModel.identityPhoto())
    }

    const isEmptyOrNull = (value) => {
        return value === "" || String(value) === 'null'
    }

    const validate = () => {
        let newErrors = []
        if(isEmptyOrNull(name)){
            newErrors.push("Nama tidak boleh kosong")
        }
        if(isEmptyOrNull(pob)){
            newErrors.push("Tempat lahir tidak boleh kosong")
        }
        if(isEmptyOrNull(dob)){
            newErrors.push("Tanggal lahir tidak boleh kosong")
        }
        if(String(religion) == 'null'){
            newErrors.push("Agama tidak boleh kosong")
        }
        if(String(marital) == 'null'){
            newErrors.push("Status perkawinan tidak boleh kosong")
        }
        if(isEmptyOrNull(waNumber)){
            newErrors.push("Nomor WA tidak boleh kosong")
        }
        if(isEmptyOrNull(work)){
            newErrors.push("Pekerjaan tidak boleh kosong")
        }
        if(isEmptyOrNull(address)){
            newErrors.push("Alamat tidak boleh kosong")
        }
        if(isEmptyOrNull(officeAddress)){
            newErrors.push("Alamat kantor tidak boleh kosong")
        }
        if(isEmptyOrNull(relativeName)){
            newErrors.push("Nama keluarga tidak boleh kosong")
        }
        if(isEmptyOrNull(relativePhone)){
            newErrors.push("Nomor keluarga tidak boleh kosong")
        }
        if(isEmptyOrNull(relativeRelationship)){
            newErrors.push("Hubungan keluarga tidak boleh kosong")
        }
        if(profileImage === "" && (profileImageUrl === "" || profileImageUrl === null)){
            newErrors.push("Foto profil tidak boleh kosong")
        }
        if(identityImage === "" && (identityImageUrl === "" || identityImageUrl === null)){
            newErrors.push("Foto identitas tidak boleh kosong")
        }

        if(newErrors.length > 0){
            alert('Lengkapi semua data')
            setErrors(newErrors)
            return false
        }

        return true
    }

    const updateProfile = () => {
        if(!validate()){
            return
        }

        setLoading(true)
        let profileModel = new ProfileModel(
            name,
            relativeName,
            waNumber,
            relativePhone,
            work,
            officeAddress,
            address,
            religion,
            marital,
            relativeRelationship,
            pob,
            dob,
            profileImage,
            identityImage
        )
        UpdateProfile.run(props.updateProfileUrl, profileModel, () => {
            setLoading(false)
            alert("Update Profile Success")
            Routes.redirectTo(Routes.transactionsPath())
        }, (e) => {
            setLoading(false)
            alert("Update Profile Failed " + e)
        })
    }

    const onImageChange = async (event) => {
        let file = event.target.files[0];
        await ImageCompressor.compress(file, (compressedFile) => {
            setProfileImage(compressedFile)
        }, () => {
            setProfileImage(file)
        })
    }

    const onIdentityImageChange = async (event) => {
        let file = event.target.files[0];
        await ImageCompressor.compress(file, (compressedFile) => {
            setIdentityImage(compressedFile)
        }, () => {
            setIdentityImage(file)
        })
    }

    const triggerImageSelector = (id) => {
        const image = document.getElementById(id);
        image.click();
    }

    const imageSelector = (imageObject, imageUrl, text) => {
        if(imageObject){
            let src = URL.createObjectURL(imageObject)
            return (
                <img src={src} style={{ maxWidth: '100%', height: 500 }} />
            )
        } else if (imageUrl) {
            return (
                <img src={imageUrl} style={{maxWidth: '100%'}}/>
            )
        } else {
            return (
                <Box style={{ width: '100%' }} >
                    <h1>{text}</h1>
                </Box>
            )
        }
    }

    if(loading) {
        return <Loading></Loading>
    }


    return (
        <>
            <Container>
                <br />
                <br/>
                <ul>
                    {errors.map((err) => {
                        return (
                            <li key={err}>{err}</li>
                        )
                    })}
                </ul>
                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <TextFieldComponent id={"name"} value={name} callback={setName} label={"Nama Anda"}></TextFieldComponent>
                    <TextFieldComponent id={"pob"} value={pob} callback={setPob} label={"Tempat Lahir"}></TextFieldComponent>

                    <TextField
                        fullWidth
                        id="dob"
                        label="Tanggal Lahir"
                        value={dob}
                        margin="normal"
                        type="date"
                        onChange={(event) => {
                            setDob(event.target.value)
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="religion-label">Agama</InputLabel>
                        <Select
                            labelId="religion-label"
                            value={religion}
                            id="religion-select"
                            label="Agama"
                            onChange={(event) => {
                                setReligion(event.target.value)
                            }}
                        >
                            <MenuItem value={"Islam"}>Islam</MenuItem>
                            <MenuItem value={"Kristen Protestan"}>Kristen Protestan</MenuItem>
                            <MenuItem value={"Katolik"}>Katolik</MenuItem>
                            <MenuItem value={"Buddha"}>Buddha</MenuItem>
                            <MenuItem value={"Hindu"}>Hindu</MenuItem>
                            <MenuItem value={"Kong Hu Cu"}>Kong Hu Cu</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="marital-label">Status Perkawinan</InputLabel>
                        <Select
                            labelId="marital-label"
                            value={marital}
                            id="marital-select"
                            label="Status Perkawinan"
                            onChange={(event) => {
                                setMarital(event.target.value)
                            }}
                        >
                            <MenuItem value={ProfileModel.marriageStatusFalse()}>Belum menikah</MenuItem>
                            <MenuItem value={ProfileModel.marriageStatusTrue()}>Menikah</MenuItem>
                        </Select>
                    </FormControl>
                    <TextFieldComponent id={"wa_number"} value={waNumber} callback={setWaNumber} label={"Nomor Whatsapp"}></TextFieldComponent>
                    <TextFieldComponent id={"job"} value={work} callback={setWork} label={"Pekerjaan"}></TextFieldComponent>
                    <TextFieldComponent id={"address"} value={address} callback={setAddress} label={"Alamat rumah sesuai KTP"}></TextFieldComponent>
                    <TextFieldComponent id={"office_address"} value={officeAddress} callback={setOfficeAddress} label={"Alamat Kantor"}></TextFieldComponent>
                    <TextFieldComponent id={"relative_name"} value={relativeName} callback={setRelativeName} label={"Nama relasi / saudara Anda (untuk kontak darurat)"}></TextFieldComponent>
                    <TextFieldComponent id={"relative_phone"} value={relativePhone} callback={setRelativePhone} label={"Nomor telepon relasi / saudara Anda"}></TextFieldComponent>
                    <TextFieldComponent id={"relative_relationship"} value={relativeRelationship} callback={setRelativeRelationship} label={"Hubungan dengan relasi / saudara Anda"}></TextFieldComponent>

                    <Grid container spacing={1} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <Grid item xs={6}>
                            <button onClick={()=>{ triggerImageSelector('image') }}>
                                { imageSelector(profileImage, profileImageUrl, "Foto Anda") }
                            </button>
                        </Grid>
                        <Grid item xs={6}>
                            <button onClick={()=>{ triggerImageSelector('identity_image') }}>
                                { imageSelector(identityImage, identityImageUrl, "Foto KTP Anda") }
                            </button>
                        </Grid>
                    </Grid>

                    <input style={{display: 'none'}} id="image" type="file" accept="image/*" onChange={onImageChange} />
                    <input style={{display: 'none'}} id="identity_image" type="file" accept="image/*" onChange={onIdentityImageChange} />

                    <Button variant="contained" fullWidth onClick={()=>{
                        updateProfile()
                    }}>Update</Button>
                </Box>
            </Container>
        </>
    );
}