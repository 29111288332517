const { Image } = require('image-js');
import * as Sentry from "@sentry/react";
export default class ImageCompressor {
    static async compress(image, onSuccess, onFailed) {
        try {
            onFailed('not compressing')
            // const file = await Image.load(await image.arrayBuffer())
            // const compressed = file.resize({ width: 1200}).rotate(0)
            // const blob = await compressed.toBlob(image.type,0.8)
            // blob.name = image.name
            // onSuccess(blob)
        } catch(e) {
            Sentry.captureException(e);
            onFailed(e.message)
        }
    }
    static async rotate(image, onSuccess, onFailed) {
        try {
            const file = await Image.load(await image.arrayBuffer())
            const compressed = file.resize({ width: 1200}).rotate(90)
            const blob = await compressed.toBlob(image.type,0.8)
            blob.name = image.name
            onSuccess(blob)
        } catch(e) {
            Sentry.captureException(e);
            onFailed()
        }
    }
}