import { Container, Box, AppBar, Toolbar, IconButton, Typography, Button, ButtonGroup } from '../vendor/mui'
import Routes from "../config/Routes";
import { Session } from '../business/Session'
import {useEffect, useState} from "react";

export default function ProfileChooser(props) {

    const [chosenProfile, setChosenProfile] = useState(1)

    useEffect(() => {
        setChosenProfile(props.chosenProfile)
    })

    const variantOne = (chosenProfile == 1) ? 'contained' : 'outlined';
    const variantTwo = (chosenProfile == 2) ? 'contained' : 'outlined';

    return (
        <ButtonGroup variant="contained" aria-label="primary button group">
            <Button onClick={() => { window.location.href=Routes.profilePath()}} variant={variantOne}>
                Profile 1
            </Button>
            <Button onClick={() => { window.location.href=Routes.profilePath2()}} variant={variantTwo}>
                Profile 2
            </Button>
        </ButtonGroup>
    )
}
