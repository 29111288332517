export class ProfileModel {
    constructor(
        name,
        relativeName,
        phone,
        relativePhone,
        work,
        workAddress,
        houseAddress,
        religion,
        marriageStatus,
        relativeDescription,
        birthPlace,
        birthDate,
        photo,
        identityPhoto,
        ) {
        this._name = name
        this._relativeName = relativeName
        this._phone = phone
        this._relativePhone = relativePhone
        this._work = work
        this._workAddress = workAddress
        this._houseAddress = houseAddress
        this._religion = religion
        this._marriageStatus = marriageStatus
        this._relativeDescription = relativeDescription
        this._birthPlace = birthPlace
        this._birthDateUnix = new Date(birthDate).getTime() / 1000
        this._photo = photo
        this._identityPhoto = identityPhoto
    }

    isEmptyProfile(){
        return this._name == null
    }

    name() { return this._name }
    relativeName(){ return this._relativeName }
    phone(){ return this._phone }
    relativePhone(){ return this._relativePhone }
    work(){ return this._work }
    workAddress(){ return this._workAddress }
    houseAddress(){ return this._houseAddress }
    religion(){ return this._religion }
    marriageStatus(){ return this._marriageStatus }
    marriageStatusBool(){
        return (this.marriageStatus() == ProfileModel.marriageStatusTrue())
    }
    relativeDescription(){ return this._relativeDescription }
    birthPlace(){ return this._birthPlace }
    birthDateUnix(){ return this._birthDateUnix }
    birthDate(){
        let date = new Date(this._birthDateUnix * 1000)
        return date.toISOString().split('T')[0]
    }
    photo(){ return this._photo }
    identityPhoto(){ return this._identityPhoto }

    static marriageStatusTrue(){
        return 'Menikah'
    }

    static marriageStatusFalse() {
        return 'Belum Menikah'
    }

}