import {Box, Button, Table, TableBody, TableCell, TableContainer, TableRow} from "../vendor/mui";

export default function RoomBooker(props) {
    return (
        <>

            <h1>Detail Harga</h1>
            <h2>{props.pricing.roomDisplayName}</h2>
            <TableContainer component={Box}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Harga Kamar</TableCell>
                            <TableCell padding={'none'}  align="left">{props.pricing.roomCost}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Deposit AC ( Keep 6 Bulan )</TableCell>
                            <TableCell padding={'none'}  align="left">{props.pricing.depositCost}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Harga AC</TableCell>
                            <TableCell padding={'none'}  align="left">{props.pricing.extraAcCost}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Harga Extra Person</TableCell>
                            <TableCell padding={'none'}  align="left">{props.pricing.extraPersonCost}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Harga Addons</TableCell>
                            <TableCell padding={'none'}  align="left">{props.pricing.addonsCost}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Total</TableCell>
                            <TableCell padding={'none'}  align="left">{props.pricing.price}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer component={Box}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Extra Person</TableCell>
                            <TableCell padding={'none'}  align="left">
                                <input type="checkbox" checked={props.extraPerson} onChange={() => props.onExtraPersonChange(!props.extraPerson)} disabled={props.disableAddons ? 'disabled':''}></input>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell padding={'none'}  align="left">Extra AC</TableCell>
                            <TableCell padding={'none'}  align="left">
                                <input type="checkbox" checked={props.extraAc} onChange={() => props.onExtraAcChange(!props.extraAc)} disabled={props.disableAddons ? 'disabled':''}></input>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell padding={'none'}  align="left"></TableCell>
                            <TableCell padding={'none'}  align="left">
                                <Button variant="contained" onClick={props.onBook}>Booking</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
