import {
    Box,
    Button,
    TableContainer, Table, TableBody, TableRow, TableCell, Select, MenuItem, Container
} from '../vendor/mui'
import {useEffect, useState} from "react";
import {Booking} from "../business/Booking";
import RoomFilter from "./RoomFilter";

export default function WeeklyBooker(props) {
    const display = () => {
        if (props.hide === true){
            return { display: 'none' }
        }
    }

    const find = async (date, timeUnit, setRooms) => {
        await Booking.filterRoomsWeekly(date,timeUnit, props.houseId, (splitted_rooms) => {
            setRooms(splitted_rooms)
        });
    }

    useEffect(() => {

    },[])

    return (
        <Container>
            <RoomFilter
                display={display()}
                title={"Mingguan"}
                type={"weekly"}
                subtitle={"Berapa Minggu?"}
                options={[1,2,3]}
                find={find}>
            </RoomFilter>
        </Container>
    )
}
