import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
export class RegisterPushNotification {
    static async run(token, onSuccess, onFailed) {
        const url = Api.apiUsersWebPushNotifications()
        const body = new FormData()
        body.append('token', token)

        try {
            const response = await FetchAdapter.post(url, body)
            onSuccess(response);
        } catch {
            onFailed();
        }
    }
}