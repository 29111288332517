import {useEffect, useState, Fragment} from "react";
import { GetAnnouncement } from '../business/GetAnnouncement';

import {
    Divider, ListItem, ListItemButton, ListItemIcon, ListItemText
} from "../vendor/mui";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

export default function Announcements(props){
    const [announcements, setAnnouncements] = useState([])

    useEffect(() => {
        getAnnouncements()
    }, [])

    const getAnnouncements = async () => {
        setAnnouncements(await GetAnnouncement.run())
    }

    let index = 0
    return (
        <>
            {
                announcements.map((announcement)=>{
                    index += 1
                    return (
                        <Fragment key={index}>
                            <ListItem key={index} disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <PriorityHighIcon></PriorityHighIcon>
                                    </ListItemIcon>
                                    <ListItemText primary={announcement.title()} />
                                </ListItemButton>
                            </ListItem>
                            {
                                announcement.descriptions().map((description) => {
                                    index += 1
                                    return (
                                        <ListItem key={index} disablePadding>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <p>{description}</p>
                                        </ListItem>
                                    )
                                })
                            }
                            <Divider />
                        </Fragment>
                    )
                })
            }
        </>
    )
}
