export default class TransactionModel {

    constructor(transaction) {
        this.transaction = transaction
    }

    id() { return this.transaction.id }
    paymentStatus() { return this.transaction.payment_status }
    roomDetail() { return `${this.transaction.house_name} - ${this.transaction.room_name}` }

    roomName() { return this.transaction.room_name }
    houseName() { return this.transaction.house_name }
    houseId() { return this.transaction.house_id }
    paymentDue() { return this.transaction.payment_due }
    roomCost() { return this.currency(this.transaction.room_cost) }
    extraAcCost() { return this.currency(this.transaction.extra_ac_cost) }
    addonsCost() { return this.currency(this.transaction.addons_cost) }
    extraPersonCost() { return this.currency(this.transaction.extra_person_cost) }
    penaltyCost() { return this.currency(this.transaction.penalty_cost) }
    discount() { return this.currency(this.transaction.discount_amount) }
    depositCost() { return this.currency(this.transaction.deposit_cost) }
    voucherCode() { return this.transaction.voucher_code }
    extraAc() { return this.transaction.booking.extra_ac }
    extraPerson() { return this.transaction.booking.extra_person }
    roomId() { return this.transaction.booking.room_id }
    bookingStart() { return (new Date(this.transaction.booking.start * 1000)).toLocaleDateString("id-ID", { month: 'long', day: 'numeric', year: 'numeric' }) }
    bookingEnd() { return (new Date(this.transaction.booking.end * 1000)).toLocaleDateString("id-ID", { month: 'long', day: 'numeric', year: 'numeric' }) }
    bookingEndUnix() { return this.transaction.booking.end }
    isConfirmed() { return this.paymentStatus() == 'confirmed' }
    isPaid() { return this.paymentStatus() == 'paid' }
    isPending() { return this.paymentStatus() == 'pending' }
    total() { return this.currency(this.transaction.total) }
    endDate() { return this.transaction.booking.end }

    currency(input){
        return parseFloat(input).toLocaleString("ID")
    }

    hasVoucher(){
        return this.voucherCode() !== '' && this.voucherCode() != null
    }
}