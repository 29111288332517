import {useEffect, useState} from "react";
import MenuBar from "../components/MenuBar";
import MonthlyBooker from "../components/MonthlyBooker";
import {FetchAdapter} from "../config/FetchAdapter";
import Api from "../config/Api";
import {
    Button,
    Grid
} from "../vendor/mui";
import Routes from "../config/Routes";

export default function Extend() {

    const [house, setHouse] = useState(null)
    const [tab, setTab] = useState(2)

    useEffect(() => {
    }, [])

    const redirectToExtendDaily = async () => {
        Routes.redirectTo(Routes.extendPathDaily())
    }
    const redirectToExtendWeekly = async () => {
        Routes.redirectTo(Routes.extendPathWeekly())
    }
    const redirectToExtendMonthly = async () => {
        Routes.redirectTo(Routes.extendPathMonthly())
    }


    return (
        <>
            <MenuBar></MenuBar>
            <center>
                <h3>Pilih jenis perpanjangan</h3>
            </center>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <center>
                        <Button variant={"contained"} onClick={redirectToExtendDaily}>Perpanjang Harian</Button>
                    </center>
                </Grid>
                <Grid item xs={12} md={6}>
                    <center>
                        <Button variant={"contained"} onClick={redirectToExtendWeekly}>Perpanjang Mingguan</Button>
                    </center>
                </Grid>
                <Grid item xs={12} md={6}>
                    <center>
                        <Button variant={"contained"} onClick={redirectToExtendMonthly}>Perpanjang Bulanan</Button>
                    </center>
                </Grid>
            </Grid>
        </>
    )
}
