import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
import { ProfileModel } from './ProfileModel'
export class GetProfile {
    static async run(url=Api.profileUrl(), onSuccess, onFailed) {
        const response = await FetchAdapter.get(url)
        let marriageStatus = ""
        if (response.marriage_status) {
            marriageStatus = ProfileModel.marriageStatusTrue()
        } else {
            marriageStatus = ProfileModel.marriageStatusFalse()
        }

        const model = new ProfileModel(
            response.name,
            response.relative_name,
            response.phone,
            response.relative_phone,
            response.work,
            response.work_address,
            response.house_address,
            response.religion,
            marriageStatus,
            response.relative_description,
            response.birth_place,
            new Date(response.birth_date_unix * 1000).toISOString(),
            response.photo,
            response.identity_photo
        )

        return model;
    }
}