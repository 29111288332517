import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
export class CancelTransaction {
    static async run(transactionId, onSuccess=()=>{}, onFailed=()=>{}) {
        const url = Api.cancelTransactionUrl(transactionId)
        const body = new FormData()
        body.append('id', transactionId)

        try {
            const response = await FetchAdapter.delete(url, body)
            if(response.error){
                window.alert(response.message)
                return onFailed();
            } else {
                return onSuccess();
            }
        } catch(e) {
            onFailed();
        }
    }
}