import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
export class ConfirmPayment {
    static async run(transactionId, bankAccountName, bankName, bankAccountNumber, paymentAmount, image, onSuccess, onFailed) {
        const url = Api.confirmPaymentUrl(transactionId)
        const body = new FormData()
        body.append('bank_account_name', bankAccountName)
        body.append('bank_account_number', bankAccountNumber)
        body.append('bank_name', bankName)
        body.append('payment_amount', paymentAmount)
        body.append('image', image, image.name)

        try {
            const response = await FetchAdapter.post(url, body)
            onSuccess(response);
        } catch {
            onFailed();
        }
    }
}