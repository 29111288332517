import {useEffect, useState} from "react";
import DateHelper from "../business/DateHelper";
import {Box, Button, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow} from "../vendor/mui";
import RoomTiles from "./RoomTiles";
import Loading from "./Loading";

export default function RoomFilter(props){
    const [date, setDate] = useState(DateHelper.todayDate())
    const [timeUnit, setTimeUnit] = useState(1)
    const [rooms, setRooms] = useState([])
    const [loading, setLoading] = useState(false)
    const minDate = () => {
        return DateHelper.yesterdayDate();
    }
    useEffect(() => {
    },[])

    const onDateChange = (event) => {
        setDate(event.target.value)
    }

    const onClick = async () => {
        setLoading(true)
        await props.find(date,timeUnit,setRooms)
        setLoading(false)
    }

    if(loading){
        return ( <Loading></Loading> )
    }

    return (
        <Box sx={props.display}>
            <TableContainer component={Box}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell padding={'none'}  align="left" colSpan={2}><h4>{props.title}</h4></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left"><h4>{props.subtitle}</h4></TableCell>
                            <TableCell padding={'none'}  align="left"><h4>Mulai tanggal masuk kost?</h4></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">
                                <Select
                                    labelId="time-unit-select-label"
                                    value={timeUnit}
                                    id="time-unit-select"
                                    label={props.subtitle}
                                    onChange={(event) => {
                                        setTimeUnit(event.target.value)
                                    }}
                                >
                                    {
                                        props.options.map((value) => {
                                            return <MenuItem key={value} value={value}>{value}</MenuItem>
                                        })
                                    }
                                </Select>
                            </TableCell>
                            <TableCell padding={'none'}  align="left">
                                <input id={"date_input"} type="date" onChange={onDateChange} min={minDate()} value={date} required />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Button style={ { width: "100%" } } variant="contained" color="primary" size="large" onClick={onClick}>Cari Kamar</Button>
            <RoomTiles rooms={rooms} type={props.type} timeUnit={timeUnit} date={date}></RoomTiles>
        </Box>
    )
}
