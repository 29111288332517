import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
import RoomModel from "../components/RoomModel";
import Routes from "../config/Routes";
export class Booking {

    static onSuccessBookingHandler (transactionId) {
        Routes.redirectTo(Routes.transactionPath(transactionId))
    }

    static onFailedBookingHandler (errorMessage) {
        window.alert(errorMessage)
    }

    static async bookRoomMonthly(roomId, start, timeUnit, extraAc, extraPerson){
        let url = Api.roomBookingMonthly(roomId);
        let body = {
            start: start,
            number_of_month: timeUnit,
            extra_ac: extraAc.toString(),
            extra_person: extraPerson.toString()
        }
        const response = await FetchAdapter.jsonPost(url, body)
        this.handleBookingResponse(response)
    }

    static handleBookingResponse(response) {
        if(typeof response.transaction !== 'undefined'){
            this.onSuccessBookingHandler(response.transaction.id)
        } else {
            this.onFailedBookingHandler(response.error)
        }
    }

    static async bookRoomWeekly(roomId, start, timeUnit, extraAc, extraPerson){
        let url = Api.roomBookingWeekly(roomId);
        let body = {
            start: start,
            number_of_week: timeUnit,
            extra_ac: extraAc.toString(),
            extra_person: extraPerson.toString()
        }
        const response = await FetchAdapter.jsonPost(url, body)
        this.handleBookingResponse(response)
    }

    static async bookRoomDaily(roomId, start, timeUnit, extraAc, extraPerson){
        let url = Api.roomBookingDaily(roomId);
        let end = start + (timeUnit * 86400)
        let body = {
            start: start,
            end: end,
            extra_ac: extraAc.toString(),
            extra_person: extraPerson.toString()
        }
        const response = await FetchAdapter.jsonPost(url, body)
        this.handleBookingResponse(response)
    }

    static parseAndSplit(response) {
        var rooms = response.rooms.map((room) => {
            return new RoomModel(room)
        });

        var splitted_rooms = []
        while (rooms.length > 0) {
            splitted_rooms.push(rooms.splice(0, 5));
        }
        return splitted_rooms;
    }

    static async filterRoomsMonthly(date, timeUnit, houseId, onSuccess, onFailed) {
        const start = new Date(date).getTime() / 1000
        const response = await FetchAdapter.get(Api.filterRoomsMonthly(houseId,timeUnit,start))
        var splitted_rooms = this.parseAndSplit(response);
        onSuccess(splitted_rooms)
    }

    static async filterRoomsWeekly(date,timeUnit, houseId, onSuccess, onFailed) {
        const start = new Date(date).getTime() / 1000
        const response = await FetchAdapter.get(Api.filterRoomsWeekly(houseId,timeUnit,start))
        var splitted_rooms = this.parseAndSplit(response);
        onSuccess(splitted_rooms)
    }

    static async filterRoomsDaily(date,timeUnit, houseId, onSuccess, onFailed) {
        const start = new Date(date).getTime() / 1000
        const end = start + (timeUnit * 86400)
        const response = await FetchAdapter.get(Api.filterRoomsDaily(houseId,start,end))
        var splitted_rooms = this.parseAndSplit(response);
        onSuccess(splitted_rooms)
    }
}