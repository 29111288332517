import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

export default class Firebase {
    vapidKey = 'BBM9upy4S7jvEnppe_jN2lwNsFLUgeSJfDtgKIdoFRoEhoYfUFs80SHJkBgVTB76_YCLxu8GvW77RyKW3_1cNJ8';
    firebaseConfig = {
        apiKey: "AIzaSyAHLSXISZ-KEnb-57U-x1LS4oOVAETK-i8",
        authDomain: "cozy-kost.firebaseapp.com",
        databaseURL: "https://cozy-kost.firebaseio.com",
        projectId: "cozy-kost",
        storageBucket: "cozy-kost.appspot.com",
        messagingSenderId: "1035758127557",
        appId: "1:1035758127557:web:7fe8600f6752a6d16e23ac"
    }

    constructor() {
        this.app = initializeApp(this.firebaseConfig);
        this.messaging = getMessaging(this.app);
    }

    requestPermission(onSuccess, onError) {
        getToken(this.messaging, {vapidKey: this.vapidKey}).then((currentToken) => {
            if (currentToken) {
                console.log(currentToken)
                onSuccess(currentToken)
                // Send the token to your server and update the UI if necessary
                // ...
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
                // ...
                onError()
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // ...
            onError()
        });
    }

}

