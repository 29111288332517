import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
import { AnnouncementModel } from './AnnouncementModel'
import {NotificationModel} from "./NotificationModel";
export class GetNotifications {
    static async run(url=Api.notificationsUrl(), onSuccess, onFailed) {
        const response = await FetchAdapter.get(url)
        const modeled = response.map((item) => {
            return new NotificationModel(item.content, item.created_at)
        })
        return modeled
    }
}