import {useEffect, useState} from "react";
import {Session} from "../business/Session";
import Routes from "../config/Routes";
import MenuBar from "../components/MenuBar";
import {FetchAdapter} from "../config/FetchAdapter";
import {ProfileModel} from "../business/ProfileModel";
import Api from "../config/Api";
import TransactionModel from "../business/TransactionModel";
import imageCompression from 'browser-image-compression';

import {
    Container,
    Box,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Grid
} from "../vendor/mui";

import ImageCompressor  from "../vendor/ImageCompressor";
import TextFieldComponent from "../components/TextField";
import {ConfirmPayment} from "../business/ConfirmPayment";
import Loading from "../components/Loading";
import HouseBankDetail from "../components/HouseBankDetail";

export default function TransactionConfirmation() {

    const [transaction, setTransaction] = useState(null)
    const [bankAccountName, setBankAccountName] = useState("")
    const [bankName, setBankName] = useState("")
    const [bankAccountNumber, setBankAccountNumber] = useState("")
    const [paymentAmount, setPaymentAmount] = useState("")
    const [image, setImage] = useState("")
    const [imageNotice, setImageNotice] = useState("")
    const [loading, setLoading] = useState(false)
    const [houseId, setHouseId] = useState(null)

    useEffect(() => {
        getTransaction()
    }, [])

    const getIdFromUrl = () => {
        var parsedUrl = new URL(window.location)
        var pathnameParts = parsedUrl.pathname.split('/');
        return pathnameParts[pathnameParts.length - 2];
    }

    const getTransaction = async () => {
        let url = Api.transactionUrl(getIdFromUrl());
        const response = await FetchAdapter.get(url)
        let model = new TransactionModel(response)
        setTransaction(model)
        setHouseId(model.houseId())
    }

    const triggerImageSelector = (id) => {
        const image = document.getElementById(id);
        image.click();
    }

    const imageSelector = (imageObject, imageUrl, text) => {
        if(imageObject){
            let src = URL.createObjectURL(imageObject)
            return (
                <img src={src} style={{ maxWidth: '100%', height: 500 }} />
            )
        } else if (imageUrl) {
            return (
                <img src={imageUrl} style={{maxWidth: '100%'}}/>
            )
        } else {
            return (
                <Box style={{ width: '100%' }} >
                    <h1>{text}</h1>
                </Box>
            )
        }
    }

    if (transaction == null || loading) {
        return <Loading></Loading>
    }

    const transactionDetail = () => {
        return (
            <TableContainer component={Box}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell padding={'none'}  align="left" colSpan={2}><h4>Rincian Harga</h4></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Total</TableCell>
                            <TableCell padding={'none'}  align="left">{transaction.total()}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const onImageChange = async (event) => {
        setImageNotice("Gambar sedang di proses...")
        let file = event.target.files[0];
        await ImageCompressor.compress(file, (compressedFile) => {
            setImage(compressedFile)
        }, () => {
            setImage(file)
        })
        setImageNotice("")
    }

    const onImageRotate = async () => {
        setImageNotice("Gambar sedang di proses...")
        await ImageCompressor.rotate(image, (compressedFile) => {
            setImage(compressedFile)
        }, () => {
            window.alert("Rotasi image gagal, hubungi admin.")
        })
        setImageNotice("")
    }

    const confirmPayment = () => {
        if (bankAccountName == "") {
            alert("Nama rekening bank harus diisi")
            return
        }
        if (bankName == "") {
            alert("Nama bank harus diisi")
            return
        }
        if (bankAccountNumber == "") {
            alert("Nomor rekening bank harus diisi")
            return
        }
        if (paymentAmount == "") {
            alert("Total bayar harus diisi")
            return
        }
        if (image == "") {
            alert("Foto bukti transfer harus diisi")
            return
        }
        setLoading(true)
        ConfirmPayment.run(transaction.id(), bankAccountName, bankName, bankAccountNumber, paymentAmount, image, () => {
            alert("Confirm payment success")
            setLoading(false)
            window.location.href = Routes.transactionPath(transaction.id())
        }, () => {
            alert("Confirm payment Failed")
            setLoading(false)
        })
    }

    const rotationButton = () => {
        if(image){
            return (
                <Button variant={"outlined"} onClick={() => {
                    onImageRotate()
                }}>
                    Jika gambar terbalik, klik disini untuk putar
                </Button>
            )
        }
    }

    const imageSelectorSection = () => {
        if (imageNotice != "") {
            return (
                <h4>{imageNotice}</h4>
            )
        }
        return (
            <>
                <h4>Foto bukti transfer harus ada <span style={{color: 'red'}}>TANGGAL TRANSFER</span>, dan tidak terbalik</h4>
                {rotationButton()}
                <Grid container spacing={1} sx={{width: '100%', bgcolor: 'background.paper'}}>
                    <Grid item xs={12}>
                        <button onClick={() => {
                            triggerImageSelector('image')
                        }}>
                            {imageSelector(image, null, "Bukti Transfer")}
                        </button>
                    </Grid>
                </Grid>

                <input style={{display: 'none'}} id="image" type="file" accept="image/*" onChange={onImageChange}/>
            </>
        )
    }

    return (
        <>
            <MenuBar></MenuBar>
            <Container>
                <HouseBankDetail houseId={houseId}></HouseBankDetail>
                {transactionDetail()}
                <TextFieldComponent id={"bank_account_number"} value={bankAccountNumber} callback={setBankAccountNumber}
                                    label={"Nomor Rekening Bank Pengirim"}></TextFieldComponent>
                <TextFieldComponent id={"bank_account_name"} value={bankAccountName} callback={setBankAccountName}
                                    label={"Nama Rekening Bank Pengirim"}></TextFieldComponent>
                <TextFieldComponent id={"bank_name"} value={bankName} callback={setBankName}
                                    label={"Transfer Melalui Bank"}></TextFieldComponent>
                <TextFieldComponent id={"payment_amount"} value={paymentAmount} callback={setPaymentAmount}
                                    label={"Total Bayar"}></TextFieldComponent>
                {imageSelectorSection()}
                <br/>

                <Button variant={"contained"} onClick={confirmPayment}>Konfirmasi Pembayaran</Button>
            </Container>
        </>
    )
}
