export default class Routes {
    static rootPath(){ return '/react' }
    static errorPath(){ return '/react/error' }
    static loginPath(){ return '/react/login' }
    static housesPath(){ return '/react/houses' }
    static notificationsPath(){ return '/react/notifications' }
    static housePath(id = ':id'){ return '/react/house/' + id }
    static profilePath(){ return '/react/profile' }
    static profilePath2(){ return '/react/profile/2' }
    static transactionsPath(){ return '/react/transactions' }
    static transactionPath(id = ':id'){ return '/react/transactions/' + id }
    static extendPath(){ return '/react/extend' }
    static extendPathMonthly(){ return '/react/extend/monthly' }
    static extendPathWeekly(){ return '/react/extend/weekly' }
    static extendPathDaily(){ return '/react/extend/daily' }
    static transactionConfirmationPath(id = ':id'){ return '/react/transactions/' + id + '/confirmation' }

    static roomPath(id = ':id',type = ':monthly', timeUnit = ':one', date = ':date'){ return '/react/room/' + id + '/' + type + '/' + timeUnit + '/' + date }

    static redirectTo(path) {
        window.location.href = path;
    }
}