import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
import { ProfileModel } from './ProfileModel'
import Routes from "../config/Routes";
import TransactionModel from "./TransactionModel";
export class TranscationsFetcher {
    constructor() {
        this.transactions = []
    }

    isEmpty(){
        return this.transactions.length == 0
    }

    isAllConfirmed(){
        let allConfirmed = true
        this.transactions.forEach((transaction) => {
            if(transaction.paymentStatus() != "confirmed"){
                allConfirmed = false
            }
        })
        return allConfirmed
    }

    getLatestTransaction(){
        let ordered = this.orderedTransaction()
        return ordered[0]
    }

    orderedTransaction(){
        let unordered = this.transactions
        let ordered = unordered.sort((a,b) => { return b.bookingEndUnix() - a.bookingEndUnix() })
        return ordered
    }

    getTransactions = async () => {
        let url = Api.transactionsUrl();
        const response = await FetchAdapter.get(url)

        let transactions = response.map((item) => {
            return new TransactionModel(item)
        })

        this.transactions = transactions
    }
}