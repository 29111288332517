import { Container, Box, AppBar, Toolbar, IconButton, Typography, Button } from '../vendor/mui'
import Routes from "../config/Routes";
import { Session } from '../business/Session'

export default function MenuBar() {

    const onLogoutPress = () => {
        Session.logOut()
        window.location.href = Routes.loginPath()
    }
    const onHomePress = () => {
        window.location.href = Routes.rootPath()
    }
    const logoutButton = () => {
        if(Session.isLogin()){
            return <Button onClick={onLogoutPress} color="inherit">Logout</Button>
        }
    }
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <Button onClick={onHomePress} sx={{ flexGrow: 1 }} color="inherit">
                            Cozy Kost
                        </Button>
                        { logoutButton() }
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    );
}
