import {useEffect, useState} from "react";
import MenuBar from "../components/MenuBar";
import MonthlyBooker from "../components/MonthlyBooker";
import {FetchAdapter} from "../config/FetchAdapter";
import Api from "../config/Api";
import {
    Box,
    Container
} from "../vendor/mui";
import HouseModel from "../business/HouseModel";
import {Tab, Tabs} from "@mui/material";
import WeeklyBooker from "../components/WeeklyBooker";
import DailyBooker from "../components/DailyBooker";
import Loading from "../components/Loading";

export default function House() {

    const [house, setHouse] = useState(null)
    const [tab, setTab] = useState(2)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        getHouse()
    }, [])

    const getIdFromUrl = () => {
        var parsedUrl = new URL(window.location)
        var pathnameParts = parsedUrl.pathname.split('/');
        return pathnameParts[pathnameParts.length - 1];
    }

    const getHouse = async () => {
        setLoading(true)
        let url = Api.houseUrl(getIdFromUrl());
        const response = await FetchAdapter.get(url)
        setHouse(new HouseModel(response))
        setLoading(false)
    }

    const handleChange = (event, newValue) => {
        setTab(newValue)
    }

    const isHidden = (index) => {
        return (index !== tab)
    }

    if (loading || (house === null)) {
        return <Loading></Loading>
    }

    return (
        <>
            <MenuBar></MenuBar>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Container>
                    <h3>Cabang: {house.name()}</h3>
                </Container>
                <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Harian"/>
                    <Tab label="Mingguan"/>
                    <Tab label="Bulanan"/>
                </Tabs>
                <DailyBooker hide={isHidden(0)} houseId={getIdFromUrl()}></DailyBooker>
                <WeeklyBooker hide={isHidden(1)} houseId={getIdFromUrl()}></WeeklyBooker>
                <MonthlyBooker hide={isHidden(2)} houseId={getIdFromUrl()}></MonthlyBooker>
            </Box>
        </>
    )
}
