import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
import HouseModel from "./HouseModel";
export class HouseFetcher {
    constructor() {
    }

    getHouse = async (id) => {
        let url = Api.houseUrl(id);
        const response = await FetchAdapter.get(url)
        return new HouseModel(response)
    }
}