import {LinearProgress, Box, CircularProgress} from '../vendor/mui'

export default function Loading() {
    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <center>
                <CircularProgress></CircularProgress>
            </center>
        </Box>
    );
}
