import {useEffect, useState} from "react";
import {Session} from "../business/Session";
import Routes from "../config/Routes";
import MenuBar from "../components/MenuBar";
import {FetchAdapter} from "../config/FetchAdapter";
import {ProfileModel} from "../business/ProfileModel";
import Api from "../config/Api";
import TransactionModel from "../business/TransactionModel";
import {
    Box,
    Divider,
    List,
    ListIcon,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    PersonIcon, SystemSecurityUpdateGoodIcon
} from "../vendor/mui";
import HouseModel from "../business/HouseModel";
import { GetProfile } from "../business/GetProfile";
import Loading from "../components/Loading";

export default function Houses() {

    const [loading, setLoading] = useState(false)
    const [houses, setHouses] = useState([])

    useEffect(() => {
        getProfile()
        getHouses();
    }, [])

    const getHouses = async () => {
        setLoading(true)
        let url = Api.housesUrl();
        const response = await FetchAdapter.get(url)
        let houses = response.map((item) => {
            return new HouseModel(item)
        })
        setHouses(houses)
        setLoading(false)
    }
    
    const getProfile = async () => {
        var profile = await GetProfile.run(Api.profileUrl())
        if(profile.isEmptyProfile()){
            window.alert("Tolong isi profile dahulu.")
            Routes.redirectTo(Routes.profilePath())
        }
    }

    if(loading){
        return <Loading></Loading>
    }

    return (
        <>
            <MenuBar></MenuBar>
            {houses.map((instance) => {
                return (
                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }} key={instance.id()}>
                        <nav aria-label="main mailbox folders">
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={()=>{ Routes.redirectTo(Routes.housePath(instance.id())) }}>
                                        <ListItemIcon>
                                            <ListIcon></ListIcon>
                                        </ListItemIcon>
                                        <ListItemText primary={instance.name()} secondary={instance.address()} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </List>
                        </nav>
                    </Box>
                )
            })}
        </>
    )
}
