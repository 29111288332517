import Routes from '../config/Routes'
import {
  Alert,
  AlertTitle,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
  IconButton,
  TextField
} from "../vendor/mui";
import { useState, useEffect } from 'react';
import { Session } from '../business/Session';
import MenuBar from '../components/MenuBar'
import { Visibility } from '../vendor/mui'
import { VisibilityOff } from '../vendor/mui'

function TogglePasswordButton() {
  return null;
}

export default function Login() {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')
  const [showError, setShowError] = useState(false)
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const onSuccess = () => {
    window.location.href = Routes.rootPath();
  }
  const onFailed = () => {
    setShowError(true)
  }
  const onLoginClick = () => {
    Session.login(phoneNumber, password, onSuccess, onFailed)
  }

  const error = () => {
    if(showError){
      return (
          <Alert severity="error">
            <AlertTitle>Login Error</AlertTitle>
            Cek nomor dan password kamu
          </Alert>
      )
    }
  }

  useEffect(() => {
    Session.whenLoggedIn(() => {
      Routes.redirectTo(Routes.rootPath())
    })
  })

  const TogglePasswordButton = () => {
    if(showPassword){
      return (
          <Button onClick={handleClickShowPassword}><VisibilityOff></VisibilityOff></Button>
      )
    } else {
      return (
          <Button onClick={handleClickShowPassword}><Visibility></Visibility></Button>
      )
    }
  }


  return (
    <>
      <MenuBar></MenuBar>
      <Container>
        <div id="sidebar">
          <br/>
          <br/>
          {error()}
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField onChange={(event) => {
                      setPhoneNumber(event.target.value)
                    }} placeholder={"Nomor Telepon"}></TextField>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField placeholder={"Password"} type={showPassword ? 'text' : 'password'} onChange={(event) => {
                      setPassword(event.target.value)
                    }}
                    ></TextField>
                    <TogglePasswordButton></TogglePasswordButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Button variant={"outlined"} onClick={onLoginClick}>Login</Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Container>
    </>
  );
}