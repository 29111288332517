export default class Pricing {
    constructor(price,roomCost,depositCost,extraAcCost,extraPersonCost,addonsCost,roomDisplayName) {
        this.price = parseInt(price).toLocaleString()
        this.roomCost = parseInt(roomCost).toLocaleString()
        this.depositCost = parseInt(depositCost).toLocaleString()
        this.extraAcCost = parseInt(extraAcCost).toLocaleString()
        this.extraPersonCost = parseInt(extraPersonCost).toLocaleString()
        this.addonsCost = parseInt(addonsCost).toLocaleString()
        this.roomDisplayName = roomDisplayName
    }

    static fromJson(json) {
        var roomDisplayName = ""
        if(json.room && json.room.display_name) {
            roomDisplayName = json.room.display_name
        }
        return new Pricing(
            json.price,
            json.room_cost,
            json.deposit_cost,
            json.extra_ac_cost,
            json.extra_person_cost,
            json.addons_cost,
            roomDisplayName
        )
    }
}
