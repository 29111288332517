export default class RoomModel {
    constructor(room) {
        this.room = room
    }

    id() {
        return this.room.id
    }

    number() {
        return this.room.number
    }

    available(){
        return this.room.available
    }
}
