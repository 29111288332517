export class AnnouncementModel {
    constructor(
        title,
        description
        ) {
        this._title = title
        this._description = description
    }


    title() { return this._title }
    descriptions(){ return this._description.split("\r\n") }
}