import {
    Box, TableContainer, Table, TableBody, TableRow, TableCell
} from '../vendor/mui'
import {useEffect, useState} from "react";
import {HouseFetcher} from "../business/HouseFetcher";

export default function HouseBankDetail(props) {
    const [house, setHouse] = useState(null)

    const getHouse = async (houseId) => {
        let houseFetcher = new HouseFetcher()
        let houseModel = await houseFetcher.getHouse(props.houseId)
        setHouse(houseModel)
    }

    useEffect(() => {
        if(props.houseId == null) return
        getHouse(props.houseId);
    }, [props.houseId])

    if(house == null) {
        return (
            <>
            </>
        )
    }

    return (
        <>
            <h3>Transfer ke rekening ini</h3>
            <span>{ house.bankDetail() }</span>
        </>
    )
}
