import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, ListIcon, PersonIcon, SystemSecurityUpdateGoodIcon } from '../vendor/mui'
import {Fragment, useEffect, useState} from "react";
import {GetNotifications} from "../business/GetNotifications";
import MenuBar from "../components/MenuBar";
import Loading from "../components/Loading";
export default function Notifications() {
    const [notificationItems,setNotificationItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const getNotifications = async () => {
        setLoading(true)
        var result = await GetNotifications.run();
        setNotificationItems(result)
        setLoading(false)
    }

    useEffect(()=>{
        getNotifications();
    }, [])

    if(loading){
        return <Loading></Loading>
    }

    if(notificationItems.length == 0){
        return (
            <>
                <MenuBar></MenuBar>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemText primary={"Tidak ada notifikasi"} />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                </List>
            </>
        )
    }

    return (
        <>
            <MenuBar></MenuBar>
            <List>
                {
                    notificationItems.map((notification) => {
                    return (
                        <Fragment key={notification.createdAt()}>
                            <ListItem disablePadding key={notification.createdAt()}>
                                <ListItemButton>
                                    <ListItemText primary={notification.content()} secondary={notification.createdAtHumanized()} />
                                </ListItemButton>
                            </ListItem>
                            <Divider />
                        </Fragment>
                    )
                })
                }
            </List>
        </>
    );
}
