import {
    Box,
    Button,
    TableContainer, Table, TableBody, TableRow, TableCell
} from '../vendor/mui'
import {useEffect, useState} from "react";
import TextFieldComponent from "./TextField";
import {ApplyVoucher} from "../business/ApplyVoucher";
import {CoinVouchers} from "../business/CoinVouchers";
import {ConvertCoinVouchers} from "../business/ConvertCoinVouchers";

export default function VoucherForm(props) {

    const [voucherCode, setVoucherCode] = useState('')
    const [coinVouchers, setCoinVouchers] = useState([])

    const applyVoucher = () => {
        if (voucherCode.length === 0){
            window.alert('Kode voucher tidak boleh kosong')
            return
        }
        applyVoucherCall(props.transaction.id(), voucherCode)
    }

    const applyVoucherCall = (transactionId, voucherCode) => {
        ApplyVoucher.run(props.transaction.id(), voucherCode, () => {
            window.alert('Pemakaian Voucher berhasil')
            window.location.reload();
        })
    }

    useEffect(() => {
        CoinVouchers.run((voucher_types)=>{
            setCoinVouchers(voucher_types)
        })
    }, [])

    const form = () => {
        if (props.transaction.hasVoucher() || !props.transaction.isPending()){
            return
        }
        return (
            <TableRow>
                <TableCell padding={'none'}  align="left">
                    <TextFieldComponent size={"small"} id={"voucher_code"} value={voucherCode} callback={setVoucherCode} label={"Kode Voucher"}></TextFieldComponent>
                    <Button size={"small"} variant={"contained"} onClick={applyVoucher}>Gunakan Voucher</Button>
                    <br/>
                    <br/>
                </TableCell>
            </TableRow>
        );
    }

    const applyCoinVoucher = (voucherTypeID) => {
        ConvertCoinVouchers.run(voucherTypeID, (voucherCodeResponse)=>{
            setVoucherCode(voucherCodeResponse)
            applyVoucherCall(props.transaction.id(), voucherCodeResponse)
        },()=>{
            window.alert('Voucher tidak ditemukan')
        })
    }

    const CoinVouchersSection = () => {
        if (props.transaction.hasVoucher() || !props.transaction.isPending()){
            return
        }
        return (
            <>
                <TableContainer component={Box}>
                    <Table aria-label="simple table">
                        <TableBody>
                            {
                                coinVouchers.map((voucher, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell padding={'none'}  align="left">
                                                <Button size={"small"} variant={"contained"} onClick={()=>{applyCoinVoucher(voucher.id)}}>
                                                    {voucher.name} ({voucher.discount_value})
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                            <TableRow>
                                <TableCell padding={'none'}  align="left" colSpan={2}><h4>Voucher</h4></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    return (
        <>
            <TableContainer component={Box}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell padding={'none'}  align="left" colSpan={2}><h4>Voucher</h4></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Code</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">{props.transaction.voucherCode()}</TableCell>
                        </TableRow>
                        {form()}
                    </TableBody>
                </Table>
            </TableContainer>
            {CoinVouchersSection()}
        </>
    )
}
