import {useEffect, useState} from "react";
import MenuBar from "../components/MenuBar";
import MonthlyBooker from "../components/MonthlyBooker";
import {FetchAdapter} from "../config/FetchAdapter";
import Api from "../config/Api";
import {
    Box, Button, Container,
    Divider,
    List,
    ListIcon,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, MenuItem,
    PersonIcon, Select, SystemSecurityUpdateGoodIcon
} from "../vendor/mui";
import HouseModel from "../business/HouseModel";
import {Tab, Tabs} from "@mui/material";
import Routes from "../config/Routes";
import RoomBooker from "../components/RoomBooker";
import Pricing from "../business/Pricing";
import {TranscationsFetcher} from "../business/TransactionsFetcher";
import MonthSelect from "../components/MonthSelect";
import { BookingCheck } from "../business/BookingCheck";
import HouseBankDetail from "../components/HouseBankDetail";
import {Booking} from "../business/Booking";

export default function ExtendWeekly() {

    const [houseId, setHouseId] = useState(null)
    const [roomId, setRoomId] = useState(null)
    const [date, setDate] = useState(null)
    const [timeUnit, setTimeUnit] = useState(1)

    const getTransactions = async () => {
        let fetcher = new BookingCheck()
        await fetcher.getBookingCheck()

        let booking = fetcher.response

        setExtraAc(booking.extraAc())
        setExtraPerson(booking.extraPerson())
        setRoomId(booking.roomId())
        setDate(booking.endDate())
        setHouseId(booking.houseId())
    }

    const [pricing, setPricing] = useState(Pricing.fromJson({}))
    const [extraPerson, setExtraPerson] = useState(false)
    const [extraAc, setExtraAc] = useState(false)

    useEffect(() => {
        getTransactions();
    }, [roomId,date])

    useEffect(() => {
        if(roomId == null) return
        getRoomPricing()
    }, [roomId,extraAc,extraPerson,timeUnit])

    const getRoomPricing = async () => {
        let url = Api.roomPricingWeekly(roomId,timeUnit,date,extraPerson,extraAc);
        const response = await FetchAdapter.get(url)
        setPricing(Pricing.fromJson(response))
    }

    const roomBook = async () => {
        await Booking.bookRoomWeekly(roomId,date,timeUnit,extraAc,extraPerson)
    }

    return (
        <>
            <MenuBar></MenuBar>
            <Container>
                <HouseBankDetail houseId={houseId}></HouseBankDetail>
                <h3>Berapa minggu?</h3>
                <MonthSelect value={timeUnit} onChange={(value) => {
                    setTimeUnit(value)
                }}>
                </MonthSelect>
                <RoomBooker
                    pricing={pricing}
                    extraPerson={extraPerson}
                    extraAc={extraAc}
                    onExtraPersonChange={setExtraPerson}
                    onExtraAcChange={setExtraAc}
                    onBook={roomBook}
                    disableAddons={true}
                ></RoomBooker>
            </Container>
        </>
    )
}
