import {Session} from "../business/Session";
import Routes from "./Routes"
import * as Sentry from "@sentry/react";

export class FetchAdapter {
    static async put(url, body) {
        const response = await fetch(url, {
            method: "PUT",
            headers: this.loginHeaders(),
            body: body,
        });

        return response.json();
    }
    static async post(url, body) {
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: this.loginHeaders(),
                body: body,
            });

            return response.json();
        } catch (e) {
            Sentry.captureException(e);
            return this.handleError()
        }
    }

    static async delete(url, body) {
        try {
            const response = await fetch(url, {
                method: "DELETE",
                headers: this.loginHeaders(),
                body: body,
            });

            return response.status;
        } catch (e) {
            Sentry.captureException(e);
            return this.handleError()
        }
    }

    static async jsonPost(url, body) {
        try {
            let headers = this.loginHeaders()
            headers['Content-Type'] = 'application/json'

            const response = await fetch(url, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
            });

            return response.json();
        } catch (e) {
            Sentry.captureException(e);
            return this.handleError()
        }
    }

    static async get(url) {
        try {
            const response = await fetch(url, {
                method: "GET",
                headers: this.loginHeaders()
            });
            if(response.status == 401){
                window.alert("Invalid Session, please relogin")
                Session.logOut()
                return Routes.redirectTo(Routes.loginPath())
            }

            return response.json();
        } catch (e) {
            Sentry.captureException(e);
            // return this.handleError()
        }
    }

    static handleError(){
        return Routes.redirectTo(Routes.errorPath())
    }

    static loginHeaders(){
        const headers = {
            'X-User-Email': Session.getPhoneNumber(),
            'X-User-Token': Session.getToken(),
        }

        return headers;
    }
}