import React from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Routes from './config/Routes'

import Root from "./routes/Root";
import Error from "./routes/Error";
import Login from "./routes/Login";
import Profile from "./routes/Profile";
import Profile2 from "./routes/Profile2";
import Transactions from "./routes/Transactions";
import Transaction from "./routes/Transaction";
import TransactionConfirmation from "./routes/TransactionConfirmation";
import Houses from "./routes/Houses";
import Notifications from "./routes/Notifications";
import House from "./routes/House";
import Room from "./routes/Room";
import Extend from "./routes/Extend";
import ExtendMonthly from "./routes/ExtendMonthly";
import ExtendWeekly from "./routes/ExtendWeekly";
import ExtendDaily from "./routes/ExtendDaily";

const router = createBrowserRouter([
  {
    path: Routes.rootPath(),
    element: <Root></Root>
  },
  {
    path: Routes.loginPath(),
    element: <Login></Login>
  },
  {
    path: Routes.transactionsPath(),
    element: <Transactions></Transactions>
  },
  {
    path: Routes.transactionPath(),
    element: <Transaction></Transaction>
  },
  {
    path: Routes.transactionConfirmationPath(),
    element: <TransactionConfirmation></TransactionConfirmation>
  },
  {
    path: Routes.profilePath(),
    element: <Profile></Profile>
  },
  {
    path: Routes.housesPath(),
    element: <Houses></Houses>
  },
  {
    path: Routes.housePath(),
    element: <House></House>
  },
  {
    path: Routes.roomPath(),
    element: <Room></Room>
  },
  {
    path: Routes.extendPath(),
    element: <Extend></Extend>
  },
  {
    path: Routes.notificationsPath(),
    element: <Notifications></Notifications>
  },
  {
    path: Routes.extendPathMonthly(),
    element: <ExtendMonthly></ExtendMonthly>
  },
  {
    path: Routes.extendPathWeekly(),
    element: <ExtendWeekly></ExtendWeekly>
  },
  {
    path: Routes.extendPathDaily(),
    element: <ExtendDaily></ExtendDaily>
  },
  {
    path: Routes.profilePath2(),
    element: <Profile2></Profile2>
  },
  {
    path: Routes.errorPath(),
    element: <Error></Error>
  },
]);

function App() {
  return (
       <RouterProvider router={router} />
  );
}

export default App;
