export class NotificationModel {
    constructor(
        content,
        createdAt
        ) {
        this._content = content
        this._createdAt = createdAt
    }

    content() { return this._content }
    createdAt() { return this._createdAt }
    createdAtHumanized() { return (new Date(this.createdAt() * 1000)).toLocaleDateString("id-ID") }
}