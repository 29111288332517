import Routes from '../config/Routes'
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, ListIcon, PersonIcon, SystemSecurityUpdateGoodIcon, MessageIcon, MonetizationOnIcon } from '../vendor/mui'
import MenuBar from '../components/MenuBar'
import Announcements from "../components/Announcements";
import NotificationButton from "../components/NotificationButton";
import {useEffect, useState} from "react";
import {CheckProfile} from "../business/CheckProfile";
import Api from "../config/Api";
import {CheckProfile as CheckCoin} from "../business/CheckCoin";

export default function Root() {

    const [coinCount, setCoinCount] = useState("Loading...")
    useEffect(()=>{
        CheckProfile.run(Api.profileCheckUrl(), ()=>{
            alert('Isi profile dahulu')
            Routes.redirectTo(Routes.profilePath())
        })
        CheckCoin.run(Api.coinUrl(), (data)=> {
            setCoinCount(data.coin_count)
        })
    }, [])


    return (
        <>
            <MenuBar></MenuBar>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <nav aria-label="main mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <MonetizationOnIcon></MonetizationOnIcon>
                                </ListItemIcon>
                                <ListItemText primary="Coin" secondary={coinCount} />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem disablePadding>
                            <ListItemButton onClick={()=>{ Routes.redirectTo(Routes.transactionsPath()) }}>
                                <ListItemIcon>
                                    <ListIcon></ListIcon>
                                </ListItemIcon>
                                <ListItemText primary="Transactions" />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem disablePadding>
                            <ListItemButton onClick={()=>{ Routes.redirectTo(Routes.profilePath()) }}>
                                <ListItemIcon>
                                    <PersonIcon></PersonIcon>
                                </ListItemIcon>
                                <ListItemText primary="Profile" />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem disablePadding>
                            <ListItemButton onClick={()=>{ Routes.redirectTo(Routes.notificationsPath()) }}>
                                <ListItemIcon>
                                    <MessageIcon></MessageIcon>
                                </ListItemIcon>
                                <ListItemText primary="Notifikasi" />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <NotificationButton></NotificationButton>
                        <Announcements></Announcements>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <SystemSecurityUpdateGoodIcon></SystemSecurityUpdateGoodIcon>
                                </ListItemIcon>
                                <ListItemText primary="Cozy Kost version: react" />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                    </List>
                </nav>
            </Box>
        </>
    );
}