import {
    Box,
    Button,
    TableContainer, Table, TableBody, TableRow, TableCell, Select, MenuItem, Container
} from '../vendor/mui'
import Routes from "../config/Routes";
import {useEffect, useState} from "react";
export default function RoomTiles(props){
    useEffect(() => {
    },[props.rooms])
    
    const type = props.type;

    const HouseCell = (props) => {
        if(props.room.available() === true){
            return (
                <TableCell sx={{backgroundColor: 'green'}} onClick={()=>{
                    window.location.href = Routes.roomPath(props.room.id(),type,props.timeUnit,props.date)
                }}>
                    <span>{props.room.number()}</span>
                </TableCell>
            )
        } else {
            return (
                <TableCell sx={{backgroundColor: 'grey'}}>{props.room.number()}</TableCell>
            )
        }
    }

    return (
        <TableContainer component={Box}>
            <Table aria-label="simple table">
                <TableBody>
                    {
                        props.rooms.map((roomsArray, index) => {
                            return (
                                <TableRow key={`row-${index}`}>
                                    {
                                        roomsArray.map((room, index) => {
                                            return <HouseCell key={room.number()} room={room} timeUnit={props.timeUnit} date={props.date}></HouseCell>
                                        })
                                    }
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}