export default class Api {
    static rootUrl(){
        return process.env.REACT_APP_API_HOST || 'http://localhost'
    }
    static loginUrl(){ return `${this.rootUrl()}/api/users/sign_in` }
    static housesUrl(){ return `${this.rootUrl()}/api/houses` }
    static announcementUrl(){ return `${this.rootUrl()}/api/announcements` }
    static houseUrl(id){ return `${this.rootUrl()}/api/houses/${id}` }
    static transactionsUrl(){ return `${this.rootUrl()}/api/transactions` }
    static transactionUrl(id){ return `${this.rootUrl()}/api/transactions/${id}` }
    static notificationsUrl(){ return `${this.rootUrl()}/api/notifications` }
    static updateProfileUrl(){ return `${this.rootUrl()}/api/users/profile` }
    static confirmPaymentUrl(id){ return `${this.rootUrl()}/api/transaction/${id}/confirm_payment.json` }
    static convertVoucherType(){ return `${this.rootUrl()}/api/voucher_types/convert_coin` }
    static apiUsersWebPushNotifications(){ return `${this.rootUrl()}/api/users/web_push_notifications` }
    static profileUrl(){ return `${this.rootUrl()}/api/users/profile` }
    static coinUrl(){ return `${this.rootUrl()}/api/users/coin` }
    static profile2Url(){ return `${this.rootUrl()}/api/users/profile_2` }
    static profileCheckUrl(){ return `${this.rootUrl()}/api/users/profile_check` }
    static updateProfileUrl2(){ return `${this.rootUrl()}/api/users/profile_2` }
    static profileUrl2(){ return `${this.rootUrl()}/api/users/profile_2` }
    static bookingCheckUrl(){ return `${this.rootUrl()}/api/users/booking_check` }
    static applyVoucherUrl(id){ return `${this.rootUrl()}/api/transactions/${id}/apply_voucher` }
    static voucherTypesUrl(){ return `${this.rootUrl()}/api/voucher_types/coin_based` }
    static cancelTransactionUrl(id){ return `${this.rootUrl()}/api/transactions/${id}.json` }
    static roomPricingMonthly(roomId, timeUnit, start, extraPerson, extraAc){
        let queryParams = [];
        if (timeUnit) { queryParams.push(`number_of_month=${timeUnit}`) }
        if (start) { queryParams.push(`start=${start}`) }
        if (extraPerson == true) { queryParams.push(`extra_person=${extraPerson}`) }
        if (extraAc == true) { queryParams.push(`extra_ac=${extraAc}`) }

        return `${this.rootUrl()}/api/rooms/${roomId}/pricing?${queryParams.join('&')}`
    }
    static roomPricingDaily(roomId, timeUnit, start, extraPerson, extraAc){
        let queryParams = [];
        let end = start + (timeUnit * 86400)
        if (start) { queryParams.push(`start=${start}`) }
        if (timeUnit) { queryParams.push(`end=${end}`) }
        if (extraPerson == true) { queryParams.push(`extra_person=${extraPerson}`) }
        if (extraAc == true) { queryParams.push(`extra_ac=${extraAc}`) }

        return `${this.rootUrl()}/api/rooms/${roomId}/pricing/daily?${queryParams.join('&')}`
    }

    static roomPricingWeekly(roomId, timeUnit, start, extraPerson, extraAc){
        let queryParams = [];
        if (timeUnit) { queryParams.push(`number_of_week=${timeUnit}`) }
        if (start) { queryParams.push(`start=${start}`) }
        if (extraPerson == true) { queryParams.push(`extra_person=${extraPerson}`) }
        if (extraAc == true) { queryParams.push(`extra_ac=${extraAc}`) }

        return `${this.rootUrl()}/api/rooms/${roomId}/pricing/weekly?${queryParams.join('&')}`
    }

    static roomBookingMonthly(roomId) {
        return `${this.rootUrl()}/api/rooms/${roomId}/booking`
    }

    static roomBookingWeekly(roomId) {
        return `${this.rootUrl()}/api/rooms/${roomId}/booking/weekly`
    }

    static roomBookingDaily(roomId) {
        return `${this.rootUrl()}/api/rooms/${roomId}/booking/daily`
    }

    static filterRoomsMonthly(houseId, timeUnit, start){ return `${this.rootUrl()}/api/houses/${houseId}/filter_rooms?number_of_month=${timeUnit}&start=${start}` }
    static filterRoomsWeekly(houseId, timeUnit, start){ return `${this.rootUrl()}/api/houses/${houseId}/filter_rooms_weekly?number_of_week=${timeUnit}&start=${start}` }
    static filterRoomsDaily(houseId, start, end){ return `${this.rootUrl()}/api/houses/${houseId}/filter_rooms?start=${start}&end=${end}` }
}