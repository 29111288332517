import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
import { ProfileModel } from './ProfileModel'
export class CheckProfile {
    static async run(url=Api.profileCheckUrl(), onMissingProfile, onSuccess, onFailed) {
        const response = await FetchAdapter.get(url)
        let status = response.status;
        if(status == false){
            onMissingProfile()
        }
    }

    static async runProfile2(url=Api.profile2Url(), onMissingProfile, onSuccess, onFailed) {
        const response = await FetchAdapter.get(url)
        let id = response.id;
        if(id == null){
            onMissingProfile()
        }
    }
}