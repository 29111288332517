export default class HouseModel {

    constructor(house) {
        if(house) {
            this.house = house
        } else {
            this.house = {
                id: null,
                name: null,
                address: null,
                bank_detail: null
            }
        }
    }

    id() { return this.house.id }
    name() { return this.house.name }
    address() { return this.house.address }
    bankDetail() { return this.house.bank_detail }
}