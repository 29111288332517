import {TextField} from '../vendor/mui'

export default function TextFieldComponent(props) {
    const size = props.size || ""
    return (
        <>
            <TextField
                fullWidth
                id={props.id}
                label={props.label}
                size={size}
                margin="normal"
                value={props.value}
                onChange={(event) => {
                    props.callback(event.target.value)
                }}
            />
        </>
    );
}
