import Api from '../config/Api'
export class Session {
    static login(phoneNumber, password, onSuccess, onFailed) {
        async function postData(url = "", data = {}) {
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(data),
          });
          return response.json();
        }

        let data = {
            user: {
                email: phoneNumber,
                password: password
            }
        }

        postData(Api.loginUrl(), data).then((data) => {

            if(data.authentication_token){
                let token = this.setToken(data.authentication_token)
                this.setPhoneNumber(phoneNumber)
                onSuccess()
            } else {
                onFailed()
            }
        });
    }

    static whenLoggedOut(callback){
        if(!this.isLogin()){
            callback()
        }
    }
    static whenLoggedIn(callback){
        if(this.isLogin()){
            callback()
        }
    }

    static isLogin(){
        return (typeof this.getToken() != undefined) && !!this.getToken()
    }

    static logOut(){
        return localStorage.removeItem("token")
        return localStorage.removeItem("phone_number")
    }

    static setToken(token){
        localStorage.setItem("token", token)
        return token
    }

    static setPhoneNumber(value){
        localStorage.setItem("phone_number", value)
    }

    static getToken(){
        return localStorage.getItem("token")
    }

    static getPhoneNumber(){
        return localStorage.getItem("phone_number")
    }
}