import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
import { AnnouncementModel } from './AnnouncementModel'
export class GetAnnouncement {
    static async run(url=Api.announcementUrl(), onSuccess, onFailed) {
        const response = await FetchAdapter.get(url)
        const modeled = response.map((item) => {
            return new AnnouncementModel(item.title, item.description)
        })
        return modeled
    }
}