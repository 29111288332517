import ProfileTemplate from  './ProfileTemplate'
import Api from '../config/Api'
import {useState} from "react";
import {Button} from "../vendor/mui";
import MenuBar from "../components/MenuBar";
import Routes from "../config/Routes";
import ProfileChooser from "../components/ProfileChooser";

export default function Profile() {
    return (
        <>
            <MenuBar></MenuBar>
            <ProfileChooser chosenProfile={1}></ProfileChooser>
            <ProfileTemplate profileUrl={Api.profileUrl()} updateProfileUrl={Api.updateProfileUrl()}></ProfileTemplate>
        </>
    );
}