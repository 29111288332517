import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
import { ProfileModel } from './ProfileModel'
import Routes from "../config/Routes";
import TransactionModel from "./TransactionModel";

class BookingModel {
    constructor(booking) {
        this.booking = booking
    }

    extraAc() {
        return this.booking.room_holder.extra_ac
    }

    extraPerson() {
        return this.booking.room_holder.extra_person
    }

    roomId() {
        return this.booking.last_transaction.room_id
    }

    houseId() {
        return this.booking.last_transaction.house_id
    }

    endDate() {
        return this.booking.last_transaction.booking.end
    }

}
export class BookingCheck {
    constructor() {
        this.response = {}
    }

    getBookingCheck = async () => {
        let url = Api.bookingCheckUrl();
        const response = await FetchAdapter.get(url)

        this.response = new BookingModel(response)
    }
}