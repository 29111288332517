import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
export class CoinVouchers {
    static async run(onSuccess=()=>{}, onFailed=()=>{}) {
        const url = Api.voucherTypesUrl()

        try {
            const response = await FetchAdapter.get(url)
            if(response.error){
                window.alert(response.message)
                return onFailed();
            } else {
                return onSuccess(response['voucher_types']);
            }
        } catch {
            onFailed();
        }
    }
}