import {useEffect, useState} from "react";
import {Session} from "../business/Session";
import Routes from "../config/Routes";
import MenuBar from "../components/MenuBar";
import {FetchAdapter} from "../config/FetchAdapter";
import {ProfileModel} from "../business/ProfileModel";
import Api from "../config/Api";
import TransactionModel from "../business/TransactionModel";
import {
    Container,
    Button
} from "../vendor/mui";
import VoucherForm from "../components/VoucherForm";
import TransactionDetail from "../components/TransactionDetail";
import Loading from "../components/Loading";
import {CancelTransaction} from "../business/CancelTransaction";
import HouseBankDetail from "../components/HouseBankDetail";

export default function Transaction(props) {

    const [houseId, setHouseId] = useState(null)
    const [transaction, setTransaction] = useState(null)

    useEffect(() => {
        getTransaction()
    }, [])

    const getIdFromUrl = () => {
        var parsedUrl = new URL(window.location)
        var pathnameParts = parsedUrl.pathname.split('/');
        return pathnameParts[pathnameParts.length - 1];
    }

    const getTransaction = async () => {
        let url = Api.transactionUrl(getIdFromUrl());
        const response = await FetchAdapter.get(url)
        let model = new TransactionModel(response)

        setTransaction(model)
        setHouseId(model.houseId())
    }

    if (transaction == null) {
        return <Loading></Loading>
    }

    const redirectToPaymentConfirmation = () => {
        window.location.href = Routes.transactionConfirmationPath(transaction.id())
    }

    const cancelTransaction = () => {
        CancelTransaction.run(transaction.id(), () => {
            window.alert('Sukses batalkan transaksi')
            Routes.redirectTo(Routes.transactionsPath())
        }, () => {
            window.alert('Gagal batalkan transaksi')
        })
    }

    function confirmationButton() {
        if (!transaction.isConfirmed()) {
            return <Button variant={"contained"} onClick={redirectToPaymentConfirmation} size={'large'}>Konfirmasi Pembayaran</Button>;
        }
    }

    function cancelButton() {
        if (transaction.isPending()) {
            return <Button variant={"contained"} onClick={cancelTransaction} size={'large'} style={ { backgroundColor: 'red' } }>BATALKAN TRANSAKSI</Button>;
        }
    }

    return (
        <>
            <MenuBar></MenuBar>
            <Container>
                <HouseBankDetail houseId={houseId}></HouseBankDetail>
                <TransactionDetail transaction={transaction}></TransactionDetail>
                <VoucherForm transaction={transaction}></VoucherForm>
                <br/>
                <center>
                    {confirmationButton()}
                    <br/>
                    <br/>
                    {cancelButton()}
                </center>
            </Container>
        </>
    )
}
