import {useEffect, useState} from "react";
import MenuBar from "../components/MenuBar";
import RoomBooker from "../components/RoomBooker";
import {FetchAdapter} from "../config/FetchAdapter";
import Api from "../config/Api";
import {Box, TableCell, TableRow, TableContainer, Table, TableBody, Container, Button} from "../vendor/mui";
import Routes from "../config/Routes";
import Pricing from "../business/Pricing";
import { Booking } from "../business/Booking";
import {CheckProfile} from "../business/CheckProfile";

export default function Room() {

    const [pricing, setPricing] = useState(Pricing.fromJson({}))
    const [extraPerson, setExtraPerson] = useState(false)
    const [extraAc, setExtraAc] = useState(false)

    useEffect(() => {
        getRoomPricing()
    }, [extraAc,extraPerson])

    function getParams(offset) {
        var parsedUrl = new URL(window.location)
        var pathnameParts = parsedUrl.pathname.split('/');
        return pathnameParts[pathnameParts.length - offset];
    }

    const getIdFromUrl = () => {
        return getParams(4);
    }

    const getTypeFromUrl = () => {
        return getParams(3);
    }

    const getTimeUnitFromUrl = () => {
        return getParams(2);
    }

    const getDateFromUrl = () => {
        var dateString = getParams(1);
        var date = new Date(dateString);
        var unixTimestamp = date.getTime() / 1000;
        return unixTimestamp;
    }

    const redirectToProfileWhenNotComplete = () => {
        CheckProfile.runProfile2(Api.profile2Url(), ()=>{
            alert('Isi profile dahulu')
            Routes.redirectTo(Routes.profilePath2())
        })
    }

    const getRoomPricing = async () => {
        const type = getTypeFromUrl()
        let url = ''

        if(extraPerson){
            redirectToProfileWhenNotComplete()
        }

        switch(type){
            case 'monthly':
                url = Api.roomPricingMonthly(getIdFromUrl(),getTimeUnitFromUrl(),getDateFromUrl(),extraPerson,extraAc);
                break;
            case 'weekly':
                url = Api.roomPricingWeekly(getIdFromUrl(),getTimeUnitFromUrl(),getDateFromUrl(),extraPerson,extraAc);
                break;
            case 'daily':
                url = Api.roomPricingDaily(getIdFromUrl(),getTimeUnitFromUrl(),getDateFromUrl(),extraPerson,extraAc);
                break;

        }

        const response = await FetchAdapter.get(url)
        setPricing(Pricing.fromJson(response))
    }

    const roomBook = async () => {
        const type = getTypeFromUrl()
        var response = {};
        switch(type){
            case 'monthly':
                await Booking.bookRoomMonthly(
                    getIdFromUrl(),getDateFromUrl(),getTimeUnitFromUrl(),extraAc,extraPerson
                )
                break;
            case 'weekly':
                response = await Booking.bookRoomWeekly(
                    getIdFromUrl(),getDateFromUrl(),getTimeUnitFromUrl(),extraAc,extraPerson
                )
                break;
            case 'daily':
                response = await Booking.bookRoomDaily(
                    getIdFromUrl(),getDateFromUrl(),getTimeUnitFromUrl(),extraAc,extraPerson
                )
                break;
        }
    }

    return (
        <>
            <MenuBar></MenuBar>
            <Container>
                <RoomBooker
                    pricing={pricing}
                    extraPerson={extraPerson}
                    extraAc={extraAc}
                    onExtraPersonChange={setExtraPerson}
                    onExtraAcChange={setExtraAc}
                    onBook={roomBook}
                ></RoomBooker>
            </Container>
        </>
    )
}
