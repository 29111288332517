import {useEffect, useState} from "react";
import {Session} from "../business/Session";
import Routes from "../config/Routes";
import MenuBar from "../components/MenuBar";
import {FetchAdapter} from "../config/FetchAdapter";
import {ProfileModel} from "../business/ProfileModel";
import Api from "../config/Api";
import TransactionModel from "../business/TransactionModel";
import {
    Box,
    Divider,
    List,
    ListIcon,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    PersonIcon, SystemSecurityUpdateGoodIcon
} from "../vendor/mui";
import {TranscationsFetcher} from "../business/TransactionsFetcher";

export default function Transactions() {

    const [transactions, setTransactions] = useState([])
    const [allConfirmed, setAllConfirmed] = useState(false)

    useEffect(() => {
        getTransactions();
    }, [])

    const getTransactions = async () => {
        let fetcher = new TranscationsFetcher()
        await fetcher.getTransactions()

        if (fetcher.isEmpty()) {
            return window.location.href = Routes.housesPath()
        }
        setTransactions(fetcher.orderedTransaction())
        setAllConfirmed(fetcher.isAllConfirmed())
    }

    function extendButton() {
        return <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
            <nav aria-label="main mailbox folders">
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            Routes.redirectTo(Routes.extendPath())
                        }}>
                            <ListItemIcon>
                                <ListIcon></ListIcon>
                            </ListItemIcon>
                            <ListItemText primary={'Perpanjang'}/>
                        </ListItemButton>
                    </ListItem>
                    <Divider/>
                </List>
            </nav>
        </Box>;
    }

    return (
        <>
            <MenuBar></MenuBar>
            {allConfirmed && extendButton()}
            {transactions.map((transaction) => {
                return (
                    <Box sx={{width: '100%', bgcolor: 'background.paper'}} key={transaction.id()}>
                        <nav aria-label="main mailbox folders">
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => {
                                        Routes.redirectTo(Routes.transactionPath(transaction.id()))
                                    }}>
                                        <ListItemIcon>
                                            <ListIcon></ListIcon>
                                        </ListItemIcon>
                                        <ListItemText primary={`${transaction.roomDetail()} - ${transaction.paymentStatus()}`}
                                                      secondary={`${transaction.bookingStart()} s/d ${transaction.bookingEnd()} (${transaction.total()})`}/>
                                    </ListItemButton>
                                </ListItem>
                                <Divider/>
                            </List>
                        </nav>
                    </Box>
                )
            })}
        </>
    )
}
