import {
    Box, TableContainer, Table, TableBody, TableRow, TableCell
} from '../vendor/mui'

export default function TransactionDetail(props) {
    const paidNotice = (transaction) => {
        if(transaction.isPaid()){
            return (
                <TableCell padding={'slim'}  align="left" colSpan={2}>
                    <div style={{backgroundColor: 'darkblue', color: 'white', padding: 4, borderRadius: 10, textAlign: 'center', boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)'}}>
                        <h3>Konfirmasi pembayaran Anda telah diterima!</h3>
                        <span>Silahkan tunggu Admin mengkonfirmasi pembayaran Anda</span>
                    </div>
                </TableCell>
            )
        }
    }
    return (
        <>
        <TableContainer component={Box}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow >
                            {paidNotice(props.transaction)}
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left" colSpan={2}><h4>Rincian kamar</h4></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Kost</TableCell>
                            <TableCell padding={'none'}  align="left">{props.transaction.houseName()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Kamar</TableCell>
                            <TableCell padding={'none'}  align="left">{props.transaction.roomName()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Mulai</TableCell>
                            <TableCell padding={'none'}  align="left">{props.transaction.bookingStart()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Selesai</TableCell>
                            <TableCell padding={'none'}  align="left">{props.transaction.bookingEnd()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Status Pembayaran</TableCell>
                            <TableCell padding={'none'}  align="left">{props.transaction.paymentStatus()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Jatuh Tempo</TableCell>
                            <TableCell padding={'none'}  align="left">{props.transaction.paymentDue()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left" colSpan={2}><h4>Rincian Harga</h4></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Harga Kamar</TableCell>
                            <TableCell padding={'none'}  align="left">{props.transaction.roomCost()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Harga AC</TableCell>
                            <TableCell padding={'none'}  align="left">{props.transaction.extraAcCost()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Harga Extra Orang</TableCell>
                            <TableCell padding={'none'}  align="left">{props.transaction.extraPersonCost()}</TableCell>
                        </TableRow>
                        {/*<TableRow>*/}
                        {/*    <TableCell padding={'none'}  align="left">Harga Addons</TableCell>*/}
                        {/*    <TableCell padding={'none'}  align="left">{props.transaction.addonsCost()}</TableCell>*/}
                        {/*</TableRow>*/}
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Deposit (jika ada)</TableCell>
                            <TableCell padding={'none'}  align="left">{props.transaction.depositCost()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Penalty</TableCell>
                            <TableCell padding={'none'}  align="left">{props.transaction.penaltyCost()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding={'none'}  align="left">Discount</TableCell>
                            <TableCell padding={'none'}  align="left">{props.transaction.discount()}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <center>
                <h3>TOTAL</h3>
                <h1>{props.transaction.total()}</h1>
            </center>
        </>
    )
}
