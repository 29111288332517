import {MenuItem, Select} from "../vendor/mui";

export default function MonthSelect(props) {
    return (
        <Select
            labelId="time-unit-select-label"
            value={props.value}
            id="time-unit-select"
            label="Berapa bulan? (pilih 7 bulan untuk gratis 1 bulan, 15 bulan untuk gratis 3 bulan)"
            onChange={(event) => {
                props.onChange(event.target.value)
            }}
        >
            {
                [1,2,3,4,5,6,7,8,9,10,11,12,15].map((value) => {
                    return <MenuItem key={value} value={value}>{value}</MenuItem>
                })
            }
        </Select>
    )
}