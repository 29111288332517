import Routes from '../config/Routes'
import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    ListIcon,
    PersonIcon,
    SystemSecurityUpdateGoodIcon,
    MessageIcon,
    ButtonGroup, Button
} from '../vendor/mui'
import MenuBar from '../components/MenuBar'
import Announcements from "../components/Announcements";
import NotificationButton from "../components/NotificationButton";
import {useEffect} from "react";
import {CheckProfile} from "../business/CheckProfile";
import Api from "../config/Api";

export default function Error() {

    return (
        <>
            <MenuBar></MenuBar>
            <center>
                <h1>500</h1>
                <p>Internal Server Error</p>

                <p>Tolong coba lagi nanti</p>

                <ButtonGroup variant="contained" aria-label="primary button group">
                    <Button onClick={() => { window.location.href=Routes.rootPath()}}>
                        Coba Lagi
                    </Button>
                </ButtonGroup>
            </center>
        </>
    );
}