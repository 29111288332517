import Api from '../config/Api'
import { FetchAdapter } from '../config/FetchAdapter'
export class ApplyVoucher {
    static async run(transactionId, voucherCode, onSuccess=()=>{}, onFailed=()=>{}) {
        const url = Api.applyVoucherUrl(transactionId)
        const body = new FormData()
        body.append('id', transactionId)
        body.append('code', voucherCode)

        try {
            const response = await FetchAdapter.post(url, body)
            if(response.error){
                window.alert(response.message)
                return onFailed();
            } else {
                return onSuccess();
            }
        } catch {
            onFailed();
        }
    }
}