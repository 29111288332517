import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, ListIcon, PersonIcon, SystemSecurityUpdateGoodIcon } from '../vendor/mui'
import Firebase from "../vendor/firebase";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {RegisterPushNotification} from "../business/RegisterPushNotification";
export default function NotificationButton() {
    const onSuccess = (token) => {
        RegisterPushNotification.run(token, ()=>{
            alert('Sukses aktifkan notifikasi.')
        }, ()=>{
            alert('Notifikasi belum disupport di OS anda.')
        })
    }

    const onError = (error) => {
        alert("Gagal aktifkan notifikasi, hubungi admin")
    }

    const initFirebase = () => {
        try {
            let firebase = new Firebase
            firebase.requestPermission(onSuccess, onError)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <ListItem disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <NotificationsActiveIcon></NotificationsActiveIcon>
                    </ListItemIcon>
                    <ListItemText primary="Aktifkan Push Notifikasi" onClick={initFirebase} />
                </ListItemButton>
            </ListItem>
            <Divider />
        </>
    );
}
